
























import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import VColor from '@/components/VColor.vue';
import { Chrome } from 'vue-color';
import ClickOutside from '@/directives/ClickOutside';
import { showToast } from '@/utils/Toast';

@Component({
  directives: { ClickOutside },
  components: {
    VColor,
    Chrome
  }
})
export default class ColorInput extends Vue {
  @Prop() value: string;
  @Prop({ default: 'md' }) size: string;

  private color: { hex: string } = { hex: '#000000' };

  created() {
    this.color = { hex: this.value || '#00000' };
  }

  colorChanged(color: { hex: string }) {
    this.color = color;
    this.$emit('input', this.color.hex);
  }

  get colorAddClass() {
    return `color-add-${this.size}`;
  }

  open = false;

  closeDropdown() {
    this.$nextTick(() => {
      if (this.open) {
        this.open = false;
      }
    });
  }

  click(color: { hex: string }) {
    this.open = true;
  }
}
