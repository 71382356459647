var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('edit-modal',{ref:"editModal",on:{"updated":_vm.onUpdated,"created":_vm.onCreated}}),_c('confirm-modal',{ref:"confirmModal"}),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"page-subtitle text-muted text-uppercase"},[_vm._v("Lista")]),_c('button',{staticClass:"ml-auto my-auto btn btn-outline-primary",on:{"click":_vm.openCreateModal}},[_vm._v(" Aggiungi ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-4"},[_c('search',{attrs:{"load":_vm.searchItems,"placeholder":"Cerca.."},on:{"searched":_vm.onSearched,"cancel":_vm.onSearchCanceled}})],1)]),_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.items,"loading":_vm.loading},on:{"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"no-results-label",fn:function(){return [(_vm.items && _vm.items.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è nessun template di stampa qui","secondaryText":"Creane una per iniziare a stampare le etichette!","action":"Crea nuovo"},on:{"action":_vm.openCreateModal}}):_vm._e()]},proxy:true},{key:"column_document_size",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(Math.round(value.width))+"x"+_vm._s(Math.round(value.height))+" ")])])]}},{key:"column_image_size",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(Math.round(value.imageWidth))+"x"+_vm._s(Math.round(value.imageHeight))+" ")])])]}},{key:"column_document_layout",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-auto"},[_vm._v(_vm._s(value.rows)+"x"+_vm._s(value.columns))])])]}},{key:"column_actions",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[_c('fa-icon',{staticClass:"clickable text-primary ml-auto",attrs:{"icon":"edit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openEditModal(value)}}}),_c('fa-icon',{staticClass:"clickable ml-3 text-primary",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal(value)}}})],1)]}},{key:"loading",fn:function(){return [(_vm.loading)?_c('div',{staticClass:"page-content p-5 d-flex w-100 my-5"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"3x","spin":""}})],1):_vm._e()]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex"},[(_vm.page < _vm.meta.totalPages)?_c('button',{staticClass:"btn btn-outline-primary mx-auto",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Carica altri ")]):_vm._e()]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }