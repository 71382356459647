




































































































import { Source } from "@/models";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";
import EmptyView from "@/components/EmptyView.vue";

import {
  Vue,
  Component,
  Mixins,
  Watch,
  Ref,
} from "vue-property-decorator";
import {  namespace } from "vuex-class";
import PrintTemplatesMixin from "@/mixins/http/PrintTemplatesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import VColor from "@/components/VColor.vue";
import { Label } from "@/models";
import EditModal from "@/components/PrintTemplate/Edit.vue";
import { PrintTemplate } from "@/models";
import { easync } from "@/utils/http";
import { showErrorToast, showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_print_templates";
const destroyTag = "destroy_print_template";

@Component({
  components: {
    VColor,
    EditModal,
    Search,
    EmptyView,
    VPageContentSticky,
    DataTable,
    ConfirmModal,
    RoundThumb,
  },
})
export default class PrintTemplateList extends Mixins(PrintTemplatesMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() editModal: EditModal;
  @Ref() confirmModal: HTMLDivElement;

  items: PrintTemplate[] = [];
  page = 1;

  onSelected(item: PrintTemplate) {
    this.$router.push({
      name: "print_templates_show",
      params: { id: item.id },
    });
  }

  columns = [
    { name: "name", label: "Nome" },
    { name: "document_layout", label: "Layout" },
    { name: "document_size", label: "Dimensione documento" },
    { name: "image_size", label: "Dimensione immagine" },
    { name: "actions", label: "" },
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onCreated(item: PrintTemplate) {
    this.items.unshift(item);
    this.onSelected(item);
  }

  onUpdated(item: PrintTemplate) {
    const index = this.items.findIndex((o) => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getPrintTemplates(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: PrintTemplate[]}) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter((o) => id !== o.id);
  }

  openCreateModal() {
    (this.editModal as any).create();
  }

  openEditModal(item: Label) {
    (this.editModal as any).edit(item);
  }

  async openConfirmModal(item: Label) {
    const message = "Sei sicuro di eliminare questo template di stampa?";
    const confirmed = await (this.confirmModal as any).confirm(message);

    if (confirmed) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyPrintTemplate(id, destroyTag));
    if (errors) {
      showErrorToast("Si è verificato un errore in cancellazione");
      return;
    }

    this.onDeleted(data.id);
  }

  get source() {
    return sourceStore.currentSource;
  }

  mounted() {
    if (this.source) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        source: this.source?.id,
        term: this.search,
      },
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getPrintTemplates(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
