















































































import { Component, Mixins, Ref } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import PrintTemplatesMixin from '@/mixins/http/PrintTemplatesMixin';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { showToast } from '@/utils/Toast';
import ColorInput from '@/components/ColorInput.vue';
import { PrintTemplate } from '@/models';
import { PrintTemplatePayload } from '@/models/PrintTemplate';
import { sourceStore } from '@/store/typed';

const updateTag = 'update_print_template';
const createTag = 'create_print_template';

@Component({
  components: {
    VModal,
    ColorInput
  }
})
export default class EditPrintTemplate extends Mixins(PrintTemplatesMixin) {
  item: PrintTemplatePayload = null;
  @Ref() readonly modal!: VModal;
  content = '';
  isNew = false;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;

  canceled!: any | null;

  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.columns &&
      this.item.rows &&
      this.item.name &&
      this.item.height &&
      this.item.width &&
      this.item.imageWidth &&
      this.item.imageHeight
    );
  }

  title() {
    return `${this.isNew ? 'Aggiungi' : 'Modifica'} template di stampa`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.$emit('confirmed', this.item);
      this.modal.hide();
    }
  }

  protected onHidden() {
    this.reset();
    if (this.canceled) {
      this.canceled();
    }
  }

  edit(item: PrintTemplate) {
    this.isNew = false;
    this.modal.show();
    this.item = Object.assign({}, item);
  }

  reset() {
    this.item = null;
  }

  create() {
    this.isNew = true;
    this.modal.show();
    this.item = new PrintTemplatePayload();
    this.item.companyId = sourceStore.company?.id;
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {
    try {
      const resp = this.isNew
        ? await this.createPrintTemplate(this.item, createTag)
        : await this.updatePrintTemplate(this.item, updateTag);
      const emitTag = this.isNew ? 'created' : 'updated';
      this.$emit(emitTag, resp);
      this.modal.hide();
      showToast('Template di stampa Salvata!');
    } catch (err) {
      showToast('Salvataggio fallito!', {
        type: 'error'
      });
    }
  }
}
